body {
  background-image: url(img/header-auberge.jpg);
}

.footer-top {
  background-image: url(img/header-auberge.jpg);
}

.content-info-auth .room_17 ul li+li {
    display: none;
}
